import * as React from "react"
import { Wrapper, ButtonWrapper } from "./Error404.module.css"
import { OrangeTitle } from "../../css/Titre.module.css"
import Button from "./../../components/Button"
import { PaddingTop50 } from "./../../css/Padding.module.css"
const isBrowser = typeof window !== "undefined"

const Error404 = () => {
  const handleHomeButton = () => {
    if (isBrowser) {
      window.location.href = "/"
    }
  }

  return (
    <div className={Wrapper}>
      <h1 className={OrangeTitle}>Erreur 404</h1>
      <p className={PaddingTop50}>
        Oops... Cette page n'existe pas. Pour retourner à l'accueil, cliquez sur
        le bouton ci-dessous.
      </p>
      <div className={ButtonWrapper}>
        <Button alt="" onClick={() => handleHomeButton()}>
          Accueil
        </Button>
      </div>
    </div>
  )
}

export default Error404
