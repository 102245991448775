import React, { useState, useEffect } from "react"
import Layout from "./../components/Layout"
import Error404 from "./../nav/Error404"
import { Helmet } from "react-helmet"
import { getUser } from "./../auth"

const NotFoundPage = () => {
  const [user, setUser] = useState(undefined)
  useEffect(() => {
    getUser(setUser)
  }, [])

  return (
    <Layout user={user}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WYNIT | Erreur 404</title>
        <meta
          name="description"
          content="WYNIT, les bons plans marseillais pour les 15-30 ans."
        />
        <meta
          name="keywords"
          content="bons plans,marseille,réductions,jeunes,erreur,404"
        />
        <link rel="canonical" href="https://wynit.com/404" />
      </Helmet>
      <Error404 />
    </Layout>
  )
}

export default NotFoundPage
